import SiteWrapper from "../components/wrapper";
import { Helmet } from "react-helmet";
// import "./readme-thank-you@gen.scss";
import PDF from "../assets/images/PagerDuty 最新テクノロジー導入ガイド.pdf";
import PD_Readme from "../assets/images/PD_Readme.jpeg";
import triangleGreen from "../assets/images/triangle_Green.png";
import ogImage from "../assets/images/pagerduty_dxable_og.png";

const GeneratedReadmeThankYou = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>デジタルトランスフォーメーションカタログ</title>

        <meta
          name="description"
          content="デジタルトランスフォーメーションカタログ
"
        />

        <meta
          property="og:title"
          content="デジタルトランスフォーメーションカタログ"
        />

        <meta
          property="og:description"
          content="デジタルトランスフォーメーションカタログ
"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/readme-thank-you/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={ogImage}
        />
        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
      </Helmet>

      <div className="thanks-wrapper">
        <div className="relative mb-24 banner-container banner-container-green-bg">
          <p className="banner-para">
            資料ダウンロードのお申し込みをいただきありがとうございます。
            <br />
            下記の表紙画像をクリックしてダウンロードしてください。
          </p>
          <div className="absolute top-[97%] left-[50%] -translate-x-[50%]">
            <img src={triangleGreen} alt="" />
          </div>
        </div>
        <div className="my-24 center-2 ">
          <h2 className="mb-[100px] center-title">
            PagerDuty最新テクノロジー導入ガイド
          </h2>
          <div className="flex items-center jusitfy-center">
            <a
              href={PDF}
              download="PagerDuty最新テクノロジー導入ガイド.pdf"
              className="hover:opacity-80"
              target="_blank"
            >
              <img
                src={PD_Readme}
                width="290px"
                height="409px"
                alt="デジタルトランスフォーメーションカタログ"
              />
            </a>
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedReadmeThankYou;
